<template>

<!-- 校园管理 学生管理页面 -->
  <div class="container">
    <div class="header-container">
      <div class="seek-container">
        <el-select v-show="showClass" @change="editClass" v-model="getClassId" placeholder="请选择">
          <el-option
            v-for="item in classList"
            :key="item.classId"
            :label="item.className"
            :value="item.classId">
             <span style="float: left">{{ item.className }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.newGradeName }}</span>
          </el-option>
        </el-select>
       <el-select v-show="!showClass" clearable v-model="getClassId" @change="getGreadClass" filterable placeholder="请选择年级班级">
          <el-option
            v-for="(item, index) in getNewGradeClassList"
            :key="index"
            :label="item.label"
            :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.grade }}</span>
          </el-option>
        </el-select>
        <div class="select-section">
          <el-input maxlength="11" placeholder="请输入学生姓名或手机号码" v-model="getSelectData"></el-input>
          <el-button @click="geySelect">搜索</el-button>
          <el-button @click="getNewData">重置</el-button>
        </div>
      </div>
      <div class="add-container">
        <!-- 学校批量导入 -->
        <router-link  v-show="!showClass" :to="{name: 'managementStudenSchollStudentsImport'}">
          <el-button class="addUser-button">
            批量导入
          </el-button>
        </router-link>
        <!-- 学生批量导入 -->
        <router-link  v-show="showClass" :to="{name: 'managementStudenClassStudentsImport'}">
          <el-button class="addUser-button">
            批量导入
          </el-button>
        </router-link>
        <el-divider direction="vertical"></el-divider>
        <router-link :to="{name: 'managementStudentManagementAdd'}">
          <el-button class="addUser-button">
            新增
          </el-button>
        </router-link>
      </div>
    </div>
    <div class="body-container">
      <el-table
        :data="childrenList"
        @selection-change="handleSelectionChange"
        :cell-style="rowClass"
        :header-cell-style="{textAlign: 'center'}"
        stripe
        :max-height="maxHeight"
        style="width: 100%">
        <el-table-column
          type="selection">
        </el-table-column>
        <el-table-column
          label="序号"
          fixed="left"
          width="80">
          <template slot-scope="scope">
            <p class="index" style="width: 100%;">
              {{scope.$index + 1}}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="grade"
          label="年级">
        </el-table-column>
        <el-table-column
          prop="className"
          width="130"
          label="班级">
        </el-table-column>
        <el-table-column
          prop="childrenName"
          label="姓名">
        </el-table-column>
         <el-table-column
          prop="phone"
          label="手机号码"
          width="120">
        </el-table-column>
        <el-table-column
          prop="sex"
          label="性别">
        </el-table-column>
        <!-- <el-table-column
          prop="leftVisionData"
          label="左眼视力">
        </el-table-column>
        <el-table-column
          prop="rightVisionData"
          label="右眼视力">
        </el-table-column> -->
        <el-table-column
          :formatter="visionData1"
          label="裸眼视力(右/左)"
          width="150">
        </el-table-column>
         <el-table-column
          :formatter="visionData2"
          label="戴镜视力(右/左)"
          width="150">
        </el-table-column>
         <el-table-column
          prop="refractiveRightDs"
          width="150"
          label="屈光_右眼球镜DS">
        </el-table-column>
        <el-table-column
          prop="refractiveRightDc"
          width="150"
          label="屈光_右眼柱镜DC">
        </el-table-column>
        <el-table-column
          prop="refractiveLeftDs"
          width="150"
          label="屈光_左眼球镜DS">
        </el-table-column>
        <el-table-column
          prop="refractiveLeftDc"
          width="150"
          label="屈光_左眼柱镜DC">
        </el-table-column>
         <el-table-column
          prop="refractiveLeftAxis"
          width="150"
          label="左眼轴位">
        </el-table-column>
        <el-table-column
          prop="refractiveRightAxis"
          width="150"
          label="右眼轴位">
        </el-table-column>
        <el-table-column
          prop="idCard"
          width="200"
          label="身份证号">
        </el-table-column>
        <el-table-column
          prop="birthday"
          width="200"
          label="出生日期">
        </el-table-column>
        <el-table-column
          prop="studentId"
          label="学号">
        </el-table-column>
         <el-table-column
          prop="remark"
          show-overflow-tooltip
          label="备注">
        </el-table-column>
        <el-table-column
          prop="tag"
          label="是否异常">
          <template slot-scope="scope">
            <el-tag
              :type="(scope.row.visionIsNormal === 0 || scope.row.visionIsNormal === null) ? 'primary' : 'danger'"
              disable-transitions>{{scope.row.state}}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column
        label="政府名称/机构名称/学校名称/学校班级"
        >
        </el-table-column> -->
        <el-table-column label="设置"  fixed="right" width="160">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
            >
            <router-link style="color: #409EFF; text-decoration: none;" :to="{name: 'managementStudentManagementEdit', params: {
              item: scope.row
            }}">
           编辑
            </router-link>
            </el-button>
            <el-button
             @click="showDel(scope.row.childrenId)"
              size="mini"
              type="text"
              >删除</el-button>
            <el-button
            size="mini"
            type="text"
            @click="transfer(scope.row)"
            >
              转移
            </el-button>
            <el-button
            size="mini"
            type="text"
            >
           <!-- :to="{name: 'managementTestHistory', params: {item: scope.row}}" -->
              <router-link style="color: #409EFF; text-decoration: none;" :to="{name: 'managementStudentTesthistory', params: {
                item: scope.row
              }}">
                查看
              </router-link>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-container">
      <el-button @click="delList">
        批量删除
      </el-button>

      <pagination :current-page="show.startPage" :total="show.totalPage" :page-size="show.pageSize" @currentChange="handleCurrentChange" @sizeChange="setPageSize"></pagination>
        <el-dialog
          title="发送消息"
          :visible.sync="dialogVisible"
          width="50%"
          :before-close="handleClose">
          <div class="dialog-container">
            {{transferInfo}}
            <el-input type="textarea"
            maxlength="200"
            placeholder="请详细描写你对该学生的修改需求"
            resize="none"
            rows="6"
            v-model="transferData"
            ></el-input>
          </div>
          <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center;">
            <el-button style="margin: 0 20px" @click="handleClose">取 消</el-button>
            <el-button style="margin: 0 20px" type="primary" @click="send">确 定</el-button>
          </span>
        </el-dialog>
    </div>
  </div>
</template>
<script>
import storage from '@/globals/storage/index.js'
import studentService from '@/globals/service/management/student.js'
// import AdministratorService from '@/globals/service/management/Administrator.js'
import GradeClassService from '@/globals/service/management/GradeClass.js'
import messageCenterService from '@/globals/service/management/messageCenter.js'
import newDate from '@/utils/newDate.js'

export default {
  data () {
    return {
      maxHeight: document.documentElement.clientHeight - 320,
      // 转移的学生信息
      transferRow: {},
      // 转移信息
      transferInfo: '',
      // 转移消息
      transferData: '',
      // 发送消息
      dialogVisible: false,
      getNewGradeClassList: [],
      getSelectData: '',
      getClassId: null,
      getGreadId: null,
      classList: [],
      showClass: true,
      childrenListSelection: [],
      childrenList: [],
      show: {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      },
      selectRow: []
    }
  },
  created () {
    if (storage.get('startPage') && storage.get('totalPage')) {
      this.show.startPage = storage.get('startPage')
      this.show.totalPage = storage.get('totalPage')
    } else {
      this.show.startPage = 1
      this.show.totalPage = 1
    }

    this.getClassList()
    const schoolClassId = storage.get('teacherClassId')
    if (!schoolClassId) {
      this.getClassGrade()
    }
  },
  methods: {
    visionData1 (row) {
      if (row.leftVisionData === '-' && row.rightVisionData === '-') {
        return '-'
      } else if (row.leftVisionData === 0 && row.rightVisionData === 0) {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.leftVisionData) {
          left = row.leftVisionData
        }
        if (row.rightVisionData) {
          right = row.rightVisionData
        }
        return right + ' / ' + left
      }
    },
    visionData2 (row) {
      if (row.wearLeftVisionData === '-' && row.wearRightVisionData === '-') {
        return '-'
      } else if (row.wearLeftVisionData === 0 && row.wearRightVisionData === 0) {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.wearLeftVisionData) {
          left = row.wearLeftVisionData
        }
        if (row.wearRightVisionData) {
          right = row.wearRightVisionData
        }
        return right + ' / ' + left
      }
    },
    send () {
      if (this.transferData.trim() === '') {
        return this.$message({
          type: 'info',
          message: '你暂未输入内容'
        })
      }
      const schoolId = storage.get('schoolId')
      messageCenterService.addSchoolMessage({
        schoolId,
        message: this.transferData + '    /    ' + this.transferInfo
      }).then(res => {
        this.$message({
          message: '发送成功',
          type: 'success'
        })
        this.handleClose()
      })
    },
    handleClose () {
      this.dialogVisible = false
      this.transferRow = {}
      // 转移信息
      this.transferInfo = ''
      // 转移消息
      this.transferData = ''
    },
    transfer (row) {
      this.transferRow = row
      this.transferInfo = `姓名: ${this.transferRow.childrenName} , 年级: ${this.transferRow.grade} , 班级: ${this.transferRow.className} , 联系方式: ${this.transferRow.phone} , 性别: ${this.transferRow.sex} `
      this.dialogVisible = true
    },
    setPageSize (value) {
      // console.log('value1', value)
      this.show.pageSize = value
      this.getData()
    },
    handleCurrentChange (val) {
      // console.log('val2', val)
      this.show.startPage = val
      storage.set('startPage', this.show.startPage)
      this.getData()
    },
    getGreadClass (id) {
      if (id) {
        const data = this.getNewGradeClassList.filter(item => {
          return Number(item.id) === Number(id)
        })[0]
        this.getGreadId = data.gradeId
      }
      this.getSelectData = ''
      this.getData()
    },
    getClassGrade () {
      const schoolId = storage.get('schoolId')
      GradeClassService.getNewGradeClass({ schoolId, pageSize: 500, startPage: 1 }).then(res => {
        // console.log('res11', res)
        this.getNewGradeClassList = res.data.classVoList.map(item => {
          return {
            id: item.id,
            value: item.id,
            label: item.isClass,
            grade: item.grade,
            gradeId: item.newGradeId

          }
        })
        if (this.getNewGradeClassList.length > 0) {
          this.getClassId = this.getNewGradeClassList[0].value
          this.getGreadClass(this.getClassId)
        }
      })
    },
    getNewData () {
      this.show = {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
      if (!storage.get('teacherClassId')) this.getClassId = null
      if (!storage.get('teacherClassId')) this.getGreadId = null
      this.getSelectData = ''
      this.getData()
    },
    geySelect () {
      if (!this.getSelectData) {
        return this.$message({
          type: 'info',
          message: '你暂未输入搜索内容'
        })
      }
      this.show = {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
      const schoolId = storage.get('schoolId')
      let schoolClassId
      let newGradeId
      if (storage.get('teacherClassId')) schoolClassId = storage.get('teacherClassId')
      if (this.getClassId) schoolClassId = this.getClassId
      if (storage.get('teacherGradeId')) newGradeId = this.getGreadId
      if (this.getGreadId) newGradeId = this.getGreadId
      studentService.searchStudent({ schoolId, schoolClassId, newGradeId, phoneOrName: this.getSelectData + '' }).then(res => {
        this.childrenList = res.data.map(item => {
          item.leftVisionData = item.leftVisionData !== null ? item.leftVisionData : '-'
          item.rightVisionData = item.rightVisionData !== null ? item.rightVisionData : '-'
          item.idCard = item.idCard !== null && item.idCard !== '' ? item.idCard : '-'
          item.studentId = item.studentId !== null && item.studentId !== '' ? item.studentId : '-'
          item.refractiveLeftDc = item.refractiveLeftDc !== null ? item.refractiveLeftDc : '-'
          item.refractiveLeftDs = item.refractiveLeftDs !== null ? item.refractiveLeftDs : '-'
          item.refractiveRightDc = item.refractiveRightDc !== null ? item.refractiveRightDc : '-'
          item.refractiveRightDs = item.refractiveRightDs !== null ? item.refractiveRightDs : '-'
          item.refractiveLeftAxis = item.refractiveLeftAxis !== null ? item.refractiveLeftAxis : '-'
          item.refractiveRightAxis = item.refractiveRightAxis !== null ? item.refractiveRightAxis : '-'
          item.wearLeftVisionData = item.wearLeftVisionData !== null ? item.wearLeftVisionData : '-'
          item.wearRightVisionData = item.wearRightVisionData !== null ? item.wearRightVisionData : '-'
          item.remark = item.remark !== null ? item.remark : '-'
          item.rightVisionData = item.rightVisionData !== null ? item.rightVisionData : '-'
          if (item.birthday) item.birthday = newDate.formatDate(new Date(Number(item.birthday)))
          item.state = (item.visionIsNormal === 0 || item.visionIsNormal === null) ? '正常' : '异常'
          return item
        })
        this.show.totalPage = 0
      })
    },
    editClass (value) {
      storage.set('teacherClassId', value)
      this.classList.forEach(item => {
        if (Number(item.classId) === Number(value)) storage.set('teacherGradeId', item.newGradeId)
      })
      this.getData()
    },
    getClassList () {
      const teacherClassId = storage.get('teacherClassId')
      if (!teacherClassId) {
        this.showClass = false
        // this.getData()
        return
      }
      studentService.getClassId().then(res => {
        // console.log('res45', res)
        this.classList = res.data
        this.getClassId = teacherClassId
        this.getData()
      })
    },
    delList () {
      this.$confirm('此操作将永久删除当前页所选学生, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.childrenListSelection.length <= 0) return this.$message({ message: '您暂未选中任何学生，请选择学生在进行此操作', type: 'info' })
        const data = this.childrenListSelection.map(item => {
          return item.childrenId
        })
        this.del(data)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleSelectionChange (value) {
      this.childrenListSelection = value
    },
    rowClass ({ row, rowIndex }) {
      if (this.selectRow.includes(row.childrenId)) {
        return { 'background-color': 'rgba(185, 221, 249, 0.75)', 'text-align': 'center' }
      } else {
        return { 'text-align': 'center' }
      }
    },
    showDel (id) {
      this.$confirm('此操作将永久删除该学生, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del([id])
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    del (id) {
      studentService.delStudent({ childrenIdList: id }).then(res => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        if (this.childrenList.length === 1) {
          this.show.startPage -= 1
        }
        this.getData()
      })
    },
    editStartPage (index) {
      console.log('index', index)
      this.show.startPage = index
      this.getData()
    },
    getData () {
      // console.log('show', this.show)
      const schoolId = storage.get('schoolId')
      let teacherClassId
      if (storage.get('teacherClassId')) teacherClassId = storage.get('teacherClassId')
      if (this.getClassId) teacherClassId = this.getClassId
      const data = this.show
      let type = 0
      if (schoolId) type = 0
      if (teacherClassId) type = 1
      data.schoolOrClassId = !teacherClassId ? schoolId : teacherClassId
      data.type = type
      studentService.getStudent(data).then(res => {
        // console.log('res22', res)
        this.childrenList = res.data.childrenList.map(item => {
          item.leftVisionData = item.leftVisionData !== null ? item.leftVisionData : '-'
          item.rightVisionData = item.rightVisionData !== null ? item.rightVisionData : '-'
          item.idCard = item.idCard !== null && item.idCard !== '' ? item.idCard : '-'
          item.studentId = item.studentId !== null && item.studentId !== '' ? item.studentId : '-'
          item.refractiveLeftDc = item.refractiveLeftDc !== null ? item.refractiveLeftDc : '-'
          item.refractiveLeftDs = item.refractiveLeftDs !== null ? item.refractiveLeftDs : '-'
          item.refractiveRightDc = item.refractiveRightDc !== null ? item.refractiveRightDc : '-'
          item.refractiveRightDs = item.refractiveRightDs !== null ? item.refractiveRightDs : '-'
          item.refractiveLeftAxis = item.refractiveLeftAxis !== null ? item.refractiveLeftAxis : '-'
          item.refractiveRightAxis = item.refractiveRightAxis !== null ? item.refractiveRightAxis : '-'
          item.wearLeftVisionData = item.wearLeftVisionData !== null ? item.wearLeftVisionData : '-'
          item.wearRightVisionData = item.wearRightVisionData !== null ? item.wearRightVisionData : '-'
          item.remark = item.remark !== null ? item.remark : '-'
          item.rightVisionData = item.rightVisionData !== null ? item.rightVisionData : '-'
          if (item.birthday) item.birthday = newDate.formatDate(new Date(Number(item.birthday)))
          item.state = (item.visionIsNormal === 0 || item.visionIsNormal === null) ? '正常' : '异常'
          return item
        })
        this.show.totalPage = res.data.totalPage
        storage.set('totalPage', this.show.totalPage)
      })
    }
  },
  watch: {
    childrenListSelection (data) {
      this.selectRow = []
      if (data.length > 0) {
        data.forEach((item, index) => {
          this.selectRow.push(item.childrenId)
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  min-height: 100%;
  background-color: #fff;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .seek-container{
      display: flex;
      align-items: center;
      .seek-section{
        border: 1px solid #34BBEC;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        color: #333;
        /deep/.el-input__inner{
          border: none !important;
          padding: 0 10px !important;
        }
        .el-icon-search{
          color: #999999;
        }
      }
      .seek-button{
        width: 80px;
        background-color: #34BBEC;
        margin-left: 20px;
        border-radius: 20px;
        color: #fff;
      }
    }
    .add-container{
      flex-shrink: 0;
      .addUser-button{
        background-color: #34BBEC;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
  .body-container{
    padding: 20px 0;
    flex: 1;
  }
  .footer-container{
    display: flex;
    width: 100%;
    padding: 33px 0;
    .el-button{
      margin-right: 160px;
    }
  }
}
.select-section{
  display: flex;
  .el-input{
    width: 200px;
    margin: 0 5px;
  }
  .el-button{
    margin: 0 5px;
  }
}

</style>
